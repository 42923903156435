export const environment = {
  production: true,
  enviromentName: 'DEV',
  apiUrl: 'https://testes2.dgartes.qs.pt/api',
  msalAuthConfig: {
    clientId: 'ebbd2688-7a47-4e2d-a5f3-9fd255756839',
    redirectUri:'https://testes2.dgartes.qs.pt/interno/authorized',
    authority: 'https://login.microsoft.com/6718e205-5765-471c-9d24-9839d84a5f2a'
  },
  oauthConfig: {
    clientId: '5862748686083813095',
    redirectUri: 'https://testes2.dgartes.qs.pt/login/govpt/authorized',
    scope:
      'http://interop.gov.pt/MDC/Cidadao/NIC http://interop.gov.pt/MDC/Cidadao/NomeProprio http://interop.gov.pt/MDC/Cidadao/NIF',
    loginUrl: 'https://preprod.autenticacao.gov.pt/OAuth/AskAuthorization',
    responseType: 'token', //impkicit flow
    showDebugInformation: true,
    requireHttps: false,
    oidc: false,
    customQueryParams:{
        state: 'random-string'
    }
  },
};
